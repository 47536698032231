<template>
  <q-dialog v-model="isOpen">
    <q-card
      v-if="interaction"
      style="min-width: 60vw;"
    >
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">
          {{ $t('Interaction') + ': ' + interaction.id }}
        </div>

        <q-space />

        <q-btn
          v-close-popup
          icon="close"
          flat
          round
          dense
        />
      </q-card-section>

      <q-card-section>
        <form-builder :schema="schema" />
      </q-card-section>

      <q-card-section class="text-center">
        <q-btn
          color="dark"
          :label="$t('Close')"
          class="q-mr-sm"
          @click="close"
        />

        <q-btn
          color="light-blue-9"
          :label="$t('Save')"
          @click="save"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Interaction',
  data () {
    return {
      isOpen: false,
      states: ['new'],
      id: null
    }
  },
  computed: {
    ...mapGetters([
      'interaction',
      'isOpenInteraction',
      'interactionId',
      'appOptions'
    ]),
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'select',
                label: this.$t('Status'),
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                field: 'state',
                value: this.interaction.state,
                options: this.states,
                disabled: true,
                required: true,
                customLabel: (row) => {
                  const text = row[0].toUpperCase() + row.slice(1).toLowerCase()
                  return this.$t(text)
                },
                onChange: (state) => {
                  this.updateInteraction({ state })
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Store'),
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                field: 'shop',
                value: this.interaction._embedded.shop,
                required: true,
                disabled: true,
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { field: 'state', type: 'in', values: ['active','blocked'] }
                    ]
                  }

                  return this.$service.shop.getAll(query)
                },
                onChange: shop => {
                  this.updateInteractionEmbedded({ shop })
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Order'),
                wrapperStyleClasses: 'col-12 col-md-12 q-pa-xs',
                field: 'order',
                value: this.interaction._embedded.order,
                customLabel: row => {
                  if (row && typeof row === 'object') {
                    const date = typeof row.created === 'object'
                      ? row.created.date
                      : row.created

                    return `${row.id}: ${row._embedded.profile.name} - ${row._embedded.phone.phone} - ${row.state} - ${this.$moment(date).format(this.appOptions.formats.date)}`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: []
                  }

                  if (this.interaction._embedded.shop) {
                    query.filter.push({ type: 'eq', field: 'shop', value: this.interaction._embedded.shop.id })
                  }

                  return this.$service.order.getAll(query)
                },
                onChange: (order) => {
                  this.updateInteractionEmbedded({ order })
                },
                buttons: [
                  {
                    id: 'order',
                    label: this.$t('Open order'),
                    disabled: !this.interaction._embedded.order,
                    onClick: () => {
                      if (!this.interaction._embedded.order) {
                        return
                      }

                      const route = this.$router.resolve({ path: `/order/${this.interaction._embedded.order.type}/${this.interaction._embedded.order.id}` })
                      window.open(route.href, '_blank')
                    }
                  }
                ]
              },
              {
                type: 'multiselect',
                label: this.$t('Shipping Requests'),
                wrapperStyleClasses: 'col-12 col-md-12 q-pa-xs',
                field: 'deliveryRequest',
                value: this.interaction._embedded.deliveryRequest,
                customLabel: row => {
                  if (row && typeof row === 'object') {
                    const date = typeof row.created === 'object'
                      ? row.created.date
                      : row.created

                    return `${row.id}: ${(row._embedded.recipient || {}).name || ''} - ${(row._embedded.recipientPhone || {}).phone || ''} - ${row.state} - ${this.$moment(date).format(this.appOptions.formats.date)}`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: []
                  }

                  return this.$service.deliveryRequest.getAll(query)
                },
                onChange: deliveryRequest => {
                  this.updateInteractionEmbedded({ deliveryRequest })
                },
                buttons: [
                  {
                    id: 'dr',
                    label: this.$t('Open request'),
                    disabled: !this.interaction._embedded.deliveryRequest,
                    onClick: () => {
                      if (!this.interaction._embedded.deliveryRequest) {
                        return
                      }

                      const route = this.$router.resolve({ path: `/outbound/shipments/entity/${this.interaction._embedded.deliveryRequest.id}` })
                      window.open(route.href, '_blank')
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  watch: {
    isOpenInteraction (value) {
      if (!value) {
        return
      }

      this.id = this.interactionId
      this.isOpen = true
    },
    isOpen (value) {
      if (value) {
        this.loadInteraction(this.id)
      } else {
        this.closeInteraction()
      }
    }
  },
  methods: {
    ...mapMutations([
      'updateInteraction',
      'setInteraction',
      'closeInteraction',
      'updateInteractionEmbedded'
    ]),
    ...mapActions([
      'loadInteraction',
      'saveInteraction'
    ]),
    open (id) {
      this.id = id
      this.isOpen = true
    },
    close () {
      this.isOpen = false
      this.setInteraction(null)
      this.closeInteraction()
    },
    save () {
      return this.saveInteraction()
        .then(_ => {
          this.close()
        })
    }
  }
}
</script>
